import { createGlobalStyle } from "styled-components"

export const WIDTHS = {
    DESKTOP: 1439,
    IPRO: 1023,
    IPAD: 767,
    MOBILE: 320,
}

export default createGlobalStyle`
   *, *:before, *:after {
       margin: 0;
       padding: 0;
       box-sizing: border-box !important;
       
       font-family: ALSHauss, sans-serif;;
   }
   html {
       scroll-behavior: smooth;
       overflow-x: hidden;
       overflow-y: auto;
       position: relative;
       user-select: none;
   }
   body {
       overflow-x: hidden;
   }
   .carousel__slide-focus-ring {
       display: none;
   }
`
