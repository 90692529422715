import * as React from "react"
import log from "loglevel"

type State = {hasError: boolean}
class ErrorBoundary extends React.Component<unknown, State> {
    constructor(props: unknown) {
        super(props)
        this.state = {
            hasError: false,
        }
    }

    componentDidCatch(error: Error, info: React.ErrorInfo): void {
        this.setState({ hasError: true })
        log.error(error, info)
    }

    render(): React.ReactNode {
        if (this.state.hasError) {
            return (
                <div>
                    Произошла ошибка, пожалуйста сообщите об этом в службу поддержки по телефону: +7 (495) 025-77-77
                </div>
            )
        }
        return this.props.children
    }
}

export default ErrorBoundary
