import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import * as React from "react"
import * as ReactDOM from "react-dom"
import * as log from "loglevel"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import remote from "loglevel-plugin-remote"
import App from "./components/App"

const customJSON = (newLog: { message: never; level: { label: never }; stacktrace: never }) => ({
    msg: newLog.message,
    level: newLog.level.label,
    stacktrace: newLog.stacktrace,
})

remote.apply(log, {
    format: customJSON,
    url: "/logger",
})

log.enableAll()

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById("root"),
)
