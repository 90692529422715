import * as React from "react"
import styled from "styled-components"
import { Link } from "./FAQElement"
import AccidentStep from "./AccidentStep"
import { Header } from "./ProtectionCarousel"
import { WIDTHS } from "../globalStyles"

import step1Img from "../img/accident1.png"
import step2Img from "../img/accident2.png"
import step3Img from "../img/accident3.png"
import step4Img from "../img/accident4.png"
import accidentArrowImg from "../img/accidentArrow.svg"

const OuterWrapper = styled.div`
    padding: 0 15px;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
        }
    }
`

const InnerWrapper = styled.div`
    padding: 0 104px;
    background-color: #f0f2f5;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 0 33px;
        }
    }
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0 23px;
        }
    }
`

const StepsWrapper = styled.div`
    display: flex;
    flex-direction: row;
    padding: 45px 0 50px 0;
    justify-content: space-between;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            flex-direction: column;
        }
    }
`

const Arrow = styled.img`
    position: relative;
    top: -39px;
    left: -5%;
`

const CenteredHeader = styled(Header)`
    text-align: center;
`

const AccidentSteps = (): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <OuterWrapper>
            <InnerWrapper>
                <CenteredHeader>
                    Что делать{ width < WIDTHS.IPRO && <br /> } при страховом случае
                </CenteredHeader>
                <StepsWrapper>
                    {
                        [
                            {
                                icon: step1Img,
                                alt: "Телефон",
                                text: (
                                    <>
                                        Позвонить по телефону <Link href="tel:88003330901">8 800 333 09 01</Link>
                                    </>
                                ),
                            },
                            {
                                icon: step2Img,
                                alt: "Email",
                                text: (
                                    <>
                                        Направить документы на&nbsp;
                                        <Link href="mailto:uuusio@absolutins.ru">uuusio@absolutins.ru</Link>
                                    </>
                                ),
                            },
                            {
                                icon: step3Img,
                                alt: "Реквизиты",
                                text: (
                                    <>
                                        Указать реквизиты для получения выплаты
                                    </>
                                ),
                            },
                            {
                                icon: step4Img,
                                alt: "Компенсация",
                                text: (
                                    <>
                                        Получить компенсацию от страховой компании
                                    </>
                                ),
                            },
                        ].map((el, i, arr) => (
                            <React.Fragment key={i}>
                                <AccidentStep icon={el.icon} text={el.text} alt={el.alt} />
                                { i !== arr.length - 1 && width > WIDTHS.IPRO &&
                                    <Arrow src={accidentArrowImg} alt="Стрелка" />
                                }
                            </React.Fragment>
                        ))
                    }
                </StepsWrapper>
            </InnerWrapper>
        </OuterWrapper>
    )
}

export default AccidentSteps
