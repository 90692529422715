import * as React from "react"
import styled from "styled-components"
import slideIconImg from "../img/slide-icon.png"
import { WIDTHS } from "../globalStyles"

const Wrapper = styled.div`
    height: 378px;
    padding: 20px 20px 20px 0;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            height: 250px;
        }
    }
`

const InnerWrapper = styled.div`
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: start;
    padding: 40px 20px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding: 30px 20px;
        }
    }
`

const Icon = styled.img`
    width: 37px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            width: 23px;
        }
    }
`

const Header = styled.div`
    font-size: 24px;
    font-weight: 700;
    color: #343434;
    padding-top: 40px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            font-size: 21px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 15px;
            padding-top: 30px;
        }
    }
`

const Text = styled.div`
    font-size: 14px;
    padding-top: 20px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            font-size: 12px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 9px;
        }
    }
`

type Props = { header: string, text: string }
const ProtectionSlide = (props: Props): JSX.Element => (
    <Wrapper>
        <InnerWrapper>
            <Icon src={slideIconImg} alt="Иконка" />
            <Header>{props.header}</Header>
            <Text>{props.text}</Text>
        </InnerWrapper>
    </Wrapper>
)

export default ProtectionSlide
