import * as React from "react"
import {
    CarouselProvider,
    Slider,
    Slide,
    ButtonBack,
    ButtonNext,
} from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"
import ProtectionSlide from "./ProtectionSlide"

import carouselBackImg from "../img/carousel-back.svg"
import carouselNextImg from "../img/carousel-next.svg"

const OuterWrapper = styled.div`
    padding: 0 15px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
        }
    }
`

const InnerWrapper = styled.div`
    height: 544px;
    padding-left: 119px;
    background-color: #dedeeb;
    overflow: hidden;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding-left: 46px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding: 0 29px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0 15px;
            height: 330px;
        }
    }
`

export const Header = styled.div`
    padding-top: 56px;
    font-weight: 700;
    font-size: 44px;
    color: black;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 21px;
            padding-top: 30px;
            padding-bottom: 10px;
        }
    }
`

const sharedButtonStyle = `
    width: 50px;
    height: 50px;
    padding: 15px;
    border-radius: 10px;
    border: 0;
    background-color: #FEE600;  
    outline: none;
    margin-left: 5px;  
    &:disabled {
        background: none;
    }
`

const StyledCarouselProvider = styled(CarouselProvider)`
    position: relative;
`

const ButtonsWrapper = styled.div`
    position: absolute;
    right: 121px;
    top: -52px;
`

const StyledButtonBack = styled(ButtonBack)`
    ${sharedButtonStyle}
`

const StyledButtonNext = styled(ButtonNext)`
    ${sharedButtonStyle}
`

const Blur = styled.div`
    width: 100%;
    height: 378px;
    position: absolute;
    background: linear-gradient(90deg, transparent 90%, #dedeeb);
    z-index: 1;
`

const ProtectionCarousel = (): JSX.Element => {
    const [width, setWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <OuterWrapper>
            <InnerWrapper>
                <Header>
                    От чего защищает продукт
                </Header>
                <StyledCarouselProvider
                    naturalSlideWidth={1}
                    naturalSlideHeight={1}
                    totalSlides={6}
                    isPlaying
                    isIntrinsicHeight
                    visibleSlides={
                        width < WIDTHS.DESKTOP
                            ? width < WIDTHS.IPRO
                                ? width < WIDTHS.IPAD
                                    ? 1.1
                                    : 2.2
                                : 2.5
                            : 3.25
                    }
                    touchEnabled
                    dragEnabled={false}
                    infinite
                >
                    { width > WIDTHS.IPRO && (
                        <ButtonsWrapper>
                            <StyledButtonBack>
                                <img src={carouselBackImg} alt="Назад" />
                            </StyledButtonBack>
                            <StyledButtonNext>
                                <img src={carouselNextImg} alt="Вперед" />
                            </StyledButtonNext>
                        </ButtonsWrapper>
                    )}
                    <Blur />
                    <Slider>
                        {
                            [
                                {
                                    header: "Заблокировали счет",
                                    text: "Проконсультируем и поможем максимально оперативно разблокировать ваш счет" +
                                        " при блокировке по любым причинам, в том числе и в рамках 115 ФЗ",
                                },
                                {
                                    header: "Нужен юрист",
                                    text: "Окажем качественную юридическую поддержку вашего бизнеса по любым" +
                                        " вопросам и представим ваши интересы в суде при необходимости",
                                },
                                {
                                    header: "Проблемы с помещением",
                                    text: "Компенсируем вам потери дохода, причиненные пожаром, заливом, перерывом в" +
                                        " подаче электро- и теплоэнергии или необоснованным поднятием арендной платы" +
                                        " на 30% и более",
                                },
                                {
                                    header: "Не работает касса/терминал",
                                    text: "Компенсируем вам потери дохода из-за сбоев при оплате услуг из-за" +
                                        " механических повреждений, ПО неисправности, кражи, уничтожения платежного" +
                                        " терминала/кассового оборудования",
                                },
                                {
                                    header: "Заражение COVID-19",
                                    text: "Компенсируем вам потери дохода в результате заражения коронавирусом" +
                                        " собственника и/или ключевого сотрудника, напрямую вовлеченного в" +
                                        " предпринимательскую деятельность",
                                },
                                {
                                    header: "Подвел партнер",
                                    text: "Компенсируем вам потери дохода в случае срыва договорных обязательств по" +
                                        " поставке товаров и услуг из-за проблем с имуществом поставщиков или клиентов",
                                },
                            ].map((el, i) => (
                                <Slide index={i} key={i}>
                                    <ProtectionSlide
                                        header={el.header}
                                        text={el.text}
                                    />
                                </Slide>
                            ))
                        }
                    </Slider>
                </StyledCarouselProvider>
            </InnerWrapper>
        </OuterWrapper>
    )
}

export default ProtectionCarousel
