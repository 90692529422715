import * as React from "react"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"

const Wrapper = styled.div`
    max-width: 200px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            max-width: 473px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
        }
    }
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            flex-direction: column;
        }
    }
`

const Text = styled.div`
    font-size: 16px;
    padding-top: 30px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding-top: 0;
            padding-left: 20px;
        }
    }
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding-top: 20px;
            padding-left: 0;
            text-align: center;
        }
    }
`

type Props = {icon: string, alt: string, text: JSX.Element}
const AccidentStep = (props: Props): JSX.Element => (
    <Wrapper>
        <img src={props.icon} alt={props.alt} />
        <Text>
            {props.text}
        </Text>
    </Wrapper>
)

export default AccidentStep
