import * as React from "react"
import styled from "styled-components"
import { MouseEventHandler } from "react"
import Button from "./Button"
import { WIDTHS } from "../globalStyles"

const Wrapper = styled.div`
    height: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 0 80px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding: 0 58px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0 20px;
        }
    }
`

const Header = styled.div`
    font-weight: 700;
    font-size: 24px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 0 100px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding: 0;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 16px;
        }
    }
`

const Text = styled.div`
    flex-grow: 0.1;
    font-size: 23px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 16px;
        }
    }
`

export const NoWrap = styled.span`
    white-space: nowrap;
`

type Props = {buttonAction: MouseEventHandler}
const BannerText = (props: Props): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <Wrapper>
            <Header>
                Защитите бизнес от приостановки деятельности — быстро и просто оформите полис
            </Header>
            <Text>
                Мы окажем помощь в разблокировке счета и компенсируем убытки от остановки предпринимательской
                деятельности на сумму до <NoWrap>500 000 руб.</NoWrap>
            </Text>
            <Button
                action={props.buttonAction}
                label="Оформить полис"
                width={
                    width < WIDTHS.IPRO
                        ? width < WIDTHS.IPAD
                            ? "100%"
                            : "487px"
                        : null
                }
            />
        </Wrapper>
    )
}

export default BannerText
