import { createGlobalStyle } from "styled-components"

import aLSHaussBlack from "./font/ALSHauss-Black.woff"
import aLSHaussBold from "./font/ALSHauss-Bold.woff"
import aLSHaussRegular from "./font/ALSHauss-Regular.woff"
import navigoRegular from "./font/Navigo-Regular.woff"

export default createGlobalStyle`
    @font-face {
        font-family: "ALSHauss";
        src: url(${aLSHaussRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
     @font-face {
        font-family: "ALSHauss";
        src: url(${aLSHaussBold}) format('woff');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: "ALSHauss";
        src: url(${aLSHaussBlack}) format('woff');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: "NavigoRegular";
        src: url(${navigoRegular}) format('woff');
        font-weight: 400;
        font-style: normal;
    }
`
