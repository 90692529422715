import * as React from "react"
import styled from "styled-components"
import PurchaseStep, { IconType } from "./PurchaseStep"
import Button from "./Button"
import { Header } from "./ProtectionCarousel"
import { WIDTHS } from "../globalStyles"

const Wrapper = styled.div`
    height: 620px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding: 0 119px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 0 46px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            margin-bottom: 60px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0 15px;
        }
    }
`

const StepsWrapper = styled.div`
    display: flex;    
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            flex-wrap: wrap;
            justify-content: center;
        }
    }
`

const LeftHeader = styled(Header)`
    margin-right: auto;
`

type Props = { buttonAction: (event: React.MouseEvent<Element, MouseEvent>) => void }
const PurchaseSteps = (props: Props): JSX.Element => {
    const [width, setWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <Wrapper>
            <LeftHeader>
                Получить полис за 1 минуту
            </LeftHeader>
            <StepsWrapper>
                <PurchaseStep
                    num="1"
                    text="Введите название организации или ИНН, телефон, email"
                    icon={IconType.ARROW}
                />
                <PurchaseStep
                    num="2"
                    text="Выберите вариант оплаты договора"
                    icon={IconType.ARROW}
                />
                <PurchaseStep
                    num="3"
                    text="Оплатите договор онлайн на сайте"
                    icon={IconType.ARROW}
                />
                <PurchaseStep
                    num="4"
                    text="Получите полис, правила и памятку по email"
                    icon={IconType.TICK}
                />
            </StepsWrapper>
            <Button
                action={props.buttonAction}
                label="Оформить полис"
                width={
                    width < WIDTHS.IPRO
                        ? width < WIDTHS.IPAD
                            ? "100%"
                            : "487px"
                        : null
                }
            />
        </Wrapper>
    )
}

export default PurchaseSteps
