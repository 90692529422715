import * as React from "react"
import styled from "styled-components"
import purchaseStepArrowImg from "../img/purchase-step-arrow.svg"
import purchaseStepTickImg from "../img/purchase-step-tick.svg"
import { WIDTHS } from "../globalStyles"

export enum IconType {
    ARROW,
    TICK,
}

const StepWrapper = styled.div`
    margin: 0 12px;
    border: 2px solid #7B61FF;
    padding: 29px 20px;
    flex-basis: 0;
    flex-grow: 1;
    position: relative;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            flex: 0 1 300px;
            margin: 0 12px 21px 12px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 15px 16px;
        }
    }
`

const HeaderWrapper = styled.div`
    position: relative;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            position: absolute;    
            top: 20px;
            left: 25px;
        }
    }
`

const Num = styled.div`
    font-size: 32px;
    font-weight: 700;
    color: #343434;    
`

const Icon = styled.div`
    position: absolute;
    right: 0;
    top: 4px;
`

const Text = styled.div`
    font-size: 14px;
    padding-top: 30px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding-left: 55px;
            padding-top: 0;
        }
    }
`

type Props = { num: string, text: string, icon: IconType }
const PurchaseStep = (props: Props): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <StepWrapper>
            <HeaderWrapper>
                <Num>
                    {props.num}
                </Num>
                { width > WIDTHS.IPAD && (
                    <Icon>
                        {props.icon === IconType.ARROW
                            ? <img src={purchaseStepArrowImg} alt="Стрелка" />
                            : <img src={purchaseStepTickImg} alt="Галочка" />
                        }
                    </Icon>
                )}
            </HeaderWrapper>
            <Text>
                {props.text}
            </Text>
        </StepWrapper>
    )
}

export default PurchaseStep
