import * as React from "react"
import styled from "styled-components"
import rfLogoImg from "../img/rf-logo.png"
import abLogoImg from "../img/ab-logo.png"
import abLogoMobileImg from "../img/ab-logo-m.png"
import logoPhoneMobileImg from "../img/phone-header-m.svg"
import { WIDTHS } from "../globalStyles"

const HeaderFlex = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 22px 2vw;
`

const RfLogo = styled.img`
    max-height: 36px;
    
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            max-height: 28px;
        }
    }
`

const AbLogo = styled.div`
    flex-grow: 0.85;
    max-height: 40px;
    position: relative;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            flex-grow: 0.3;
        }
    }
`

const AbLogoImg = styled.img`
    height: 40px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            max-height: 32px;
        }
    }
`

const TelImg = styled.img`
    height: 40px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            max-height: 24px;
        }
    }
`

const TelNum = styled.a`
    font-family: NavigoRegular, sans-serif;
    font-size: 20px;
    text-decoration: none;
    color: black;
`

const PhoneText = styled.div`
    font-family: ALSHauss, sans-serif;
    font-size: 14px;
    color: #808185;
    text-align: end;
`

const Header = (): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <HeaderFlex>
            <RfLogo src={rfLogoImg} alt="Райффайзен Банк" />
            <AbLogo>
                <AbLogoImg src={width > WIDTHS.IPAD ? abLogoImg : abLogoMobileImg} alt="Абсолют Страхование" />
            </AbLogo>
            <div>
                { width > WIDTHS.IPAD
                    ? (
                        <>
                            <TelNum href="tel:88002001838">
                                8 800 200 18 38
                            </TelNum>
                            <PhoneText>
                                Бесплатно по России
                            </PhoneText>
                        </>
                    )
                    : (
                        <a href="tel:88002001838">
                            <TelImg src={logoPhoneMobileImg} alt="Телефон" />
                        </a>
                    )
                }
            </div>
        </HeaderFlex>
    )
}

export default Header
