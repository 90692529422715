import * as React from "react"
import styled from "styled-components"
import axios from "axios"
import log from "loglevel"
import { Header } from "./ProtectionCarousel"
import { WIDTHS } from "../globalStyles"

import docImg from "../img/doc.png"

const OuterWrapper = styled.div`
    height: 341px;
    padding: 0 15px;
    
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
        }
    }
`

const InnerWrapper = styled.div`
    background-color: #F6F6F6;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 104px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding-left: 31px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            flex-direction: column;
            justify-content: space-evenly;
            height: 100%;
        }
    }
`

const DocsWrapper = styled.div`
    flex-grow: 0.55;
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
            flex-grow: 0;
        }
    }
`

const Doc = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    cursor: pointer;
`

const DocDescription = styled.div`
    padding-left: 32px;
    line-height: 24px;
`

const DocTitle = styled.div`
    color: #1990FE;
`

interface DocType {
    name: string,
    size: number,
    type: string,
    url: string
}

const Documents = (): JSX.Element => {
    const [docs, setDocs] = React.useState<DocType[]>([])

    React.useEffect(() => {
        axios.get("/documents")
            .then((res) => res.data)
            .then((data) => {
                if (data.success === true && data.docs instanceof Array) {
                    setDocs(data.docs || [])
                }
            })
            .catch((e) => {
                log.error(e.message, e)
            })
    }, [])

    return docs.length > 0
        ? (
            <OuterWrapper>
                <InnerWrapper>
                    <Header>
                        Документы
                    </Header>
                    <DocsWrapper>
                        {
                            docs.map((doc, i) => (
                                <Doc onClick={() => window.open(doc.url, "_blank")} key={i}>
                                    <img src={docImg} alt="Документ" />
                                    <DocDescription>
                                        <DocTitle>{doc.name}</DocTitle>
                                        <div>{doc.type}, {doc.size} КБ</div>
                                    </DocDescription>
                                </Doc>
                            ))
                        }
                    </DocsWrapper>
                </InnerWrapper>
            </OuterWrapper>
        )
        : null
}

export default Documents
