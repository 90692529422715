const Validators = {
    company: (val: Record<string, unknown>): boolean =>
        Object.keys(val).length > 0,
    phone: (val: string): boolean =>
        val.match(/^[\d][ ][(][\d]{3}[)][ ][\d]{3}[-][\d]{2}[-][\d]{2}$/) !== null,
    email: (val: string): boolean =>
        val.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/) !== null,
}

export default Validators
