import * as React from "react"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"

import phoneBannerImg from "../img/phone-banner.png"

const BannerWrapper = styled.div`
    padding: 0 20px;
    width: 100%;
    height: 500px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            height: 642px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
            height: 280px;
        }
    }
`

const InnerWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
`

const Text = styled.div`
    font-weight: 700;
    font-size: 64px;
    position: absolute;
    color: white;
    z-index: 3;
    top: 50%;
    transform: translateY(-50%);
    left: 100px;
    line-height: 66px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            left: 3%;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            top: 100px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 28px;
            line-height: 28px;
            top: 50px;
        }
    }
`

const LightBluePiece = styled.div`
    width: 1000px;
    height: 1000px;
    background-color: #565F8F;
    transform: rotate(50deg);
    top: -40%;
    left: calc(70% - 1200px);
    position: absolute;
    z-index: 1;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            top: -10%;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            left: calc(148% - 1200px);
        }
    }
`

const DarkBluePiece = styled.div`
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: #313B6D;
    position: absolute;
`

const BeigePiece = styled.div`
    width: 1000px;
    height: 1000px;
    position: absolute;
    background-color: #FFEBE0;
    transform: rotate(-50deg);
    z-index: 2;
    right: calc(20% - 800px);
    top: -20px;
`

const PhoneImage = styled.img`
    z-index: 2;
    right: 111px;
    position: absolute;
    display: block;
    bottom: 0;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            right: 3%;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            right: 50%;
            transform: translateX(50%);
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            max-width: 180px;
        }
    }
`

const BannerMain = (): JSX.Element => (
    <BannerWrapper>
        <InnerWrapper>
            <Text>
                Защита ваших<br /> успехов
            </Text>
            <LightBluePiece />
            <DarkBluePiece />
            <BeigePiece />
            <PhoneImage src={phoneBannerImg} alt="Телефон с открытым сайтом absolutins.ru" />
        </InnerWrapper>
    </BannerWrapper>
)

export default BannerMain
