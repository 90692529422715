import * as React from "react"

import log from "loglevel"
import styled from "styled-components"
import GlobalFonts from "../font"
import GlobalStyles from "../globalStyles"

import Header from "./Header"
import BannerMain from "./BannerMain"
import BannerText from "./BannerText"
import Advantages from "./Advantages"
import ProtectionCarousel from "./ProtectionCarousel"
import FormBlock from "./FormBlock"
import Documents from "./Documents"
import FAQ from "./FAQ"
import AccidentSteps from "./AccidentSteps"
import Contacts from "./Contacts"
import Footer from "./Footer"
import ErrorBoundary from "./ErrorBoundary"
import PurchaseSteps from "./PurchaseSteps"

const Wrapper = styled.div`
    max-width: 1440px;
    margin-left: auto;
    margin-right: auto;
`

const App = (): JSX.Element => {
    const formRef = React.createRef<HTMLDivElement>()

    const scrollToRef = () => {
        try {
            formRef.current.scrollIntoView({ behavior: "smooth" })
        } catch (e) {
            log.error(e)
        }
    }

    return (
        <ErrorBoundary>
            <GlobalFonts />
            <GlobalStyles />
            <Wrapper>
                <Header />
                <BannerMain />
                <BannerText buttonAction={scrollToRef} />
                <Advantages buttonAction={scrollToRef} />
                <ProtectionCarousel />
                <PurchaseSteps buttonAction={scrollToRef} />
                <FormBlock ref={formRef} />
                <Documents />
                <FAQ />
                <AccidentSteps />
                <Contacts />
                <Footer />
            </Wrapper>
        </ErrorBoundary>
    )
}

export default App
