import * as React from "react"
import axios, { CancelTokenSource } from "axios"
import styled, { keyframes } from "styled-components"
import { Transition } from "react-transition-group"
import { TransitionStatus } from "react-transition-group/Transition"
import log from "loglevel"
import { StyledInput } from "./Form"

const AutoSuggestWrapper = styled.div`
    position: relative;
`

type SuggestionContainerType = { $status: TransitionStatus }
const SuggestionContainer = styled.div<SuggestionContainerType>`
    position: absolute;
    background: white;
    z-index: 2;
    
    opacity: ${(props) => (props.$status === "entered" ? "1" : "0")};
    transition: opacity 0.5s;
`

const SuggestionEl = styled.div`
    padding: 19px;
    border: 1px solid gray;
    border-top: none;
    line-height: 18px;
    cursor: pointer;
`

const rotate = keyframes`
    from { transform: rotate(0deg);   }
    to   { transform: rotate(360deg); }
`

type LoaderType = { $status: TransitionStatus }
export const Loader = styled.div<LoaderType>`
    width: 36px;
    height: 36px;
    position: absolute;
    right: 10px;
    top: 10px;
    border: 5px solid lightgrey;
    border-top: 5px solid #565f8f;
    border-radius: 50%;
    animation: ${rotate} 2s linear infinite;
    
    opacity: ${(props) => (props.$status === "entered" ? "1" : "0")};
    transition: opacity 0.5s;
`

type Props<Suggestion> = {
    suggestionEndpoint: string,
    onChange: (newValue: Suggestion) => void,
    $validInput: boolean,
    makeLabel: (suggestion: Suggestion) => string,
    inputPlaceholder: string,
    name: string,
}
const SuggestInput = <Suggestion extends unknown>(props: Props<Suggestion>): JSX.Element => {
    type State = {
        label: string,
        value: Suggestion,
        suggestions: Suggestion[],
        loading: boolean
    }
    const [state, setState] = React.useState<State>({
        label: "",
        value: {} as Suggestion,
        suggestions: [],
        loading: false,
    })
    const [ajaxCancel, setAjaxCancel] = React.useState<CancelTokenSource>(axios.CancelToken.source())

    const onChange = ({ currentTarget: { value } }: React.FormEvent<HTMLInputElement>): void => {
        ajaxCancel.cancel("cancel")
        setAjaxCancel(() => {
            const newCancel = axios.CancelToken.source()
            if (value.length > 2) {
                setState((currentState) => (
                    {
                        ...currentState,
                        loading: true,
                    }
                ))

                axios.get(`${props.suggestionEndpoint}/${value}`, {
                    cancelToken: newCancel.token,
                })
                    .then((res) => res.data)
                    .then(({ success, suggestions }: {success: boolean, suggestions: Suggestion[]}) => {
                        if (success) {
                            setState((currentState: State) => ({
                                ...currentState,
                                loading: false,
                                suggestions,
                            }))
                        }
                    })
                    .catch((e) => {
                        if (e.message !== "cancel") {
                            log.error("Failed fetching suggestion", e)
                        }
                    })
            }
            setState((currentState) => (
                {
                    ...currentState,
                    label: value,
                }
            ))
            return ajaxCancel
        })
    }

    const onSuggestionSelect = (suggestion: Suggestion) => {
        setState((currentState) => (
            {
                ...currentState,
                label: props.makeLabel(suggestion),
                value: suggestion,
                suggestions: [],
            }
        ))
        props.onChange(suggestion)
    }

    const onBlur = () => {
        setState((currentState) => (
            {
                ...currentState,
                suggestions: [],
            }
        ))
    }

    return (
        <AutoSuggestWrapper onBlur={onBlur}>
            <StyledInput
                type="text"
                onChange={onChange}
                value={state.label}
                $validInput={props.$validInput}
                placeholder={props.inputPlaceholder}
                name={props.name}
            />
            <Transition in={state.loading} timeout={500} mountOnEnter unmountOnExit>
                { (status) => <Loader $status={status} /> }
            </Transition>
            <Transition in={state.suggestions.length > 0} timeout={500} mountOnEnter unmountOnExit>
                { (status) => (
                    <SuggestionContainer $status={status}>
                        { state.suggestions.map((suggestion, i) => (
                            <SuggestionEl key={i} onMouseDown={() => onSuggestionSelect(suggestion)}>
                                {props.makeLabel(suggestion)}
                            </SuggestionEl>
                        )) }
                    </SuggestionContainer>
                )}
            </Transition>
        </AutoSuggestWrapper>
    )
}

export default SuggestInput
