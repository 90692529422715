import * as React from "react"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"

import globeImg from "../img/globe.png"
import phoneImg from "../img/phone.png"
import vkImg from "../img/icon-vk.svg"
import ytImg from "../img/icon-youtube.svg"
import tgImg from "../img/icon-tg.svg"
import dzenImg from "../img/icon-dzen.svg"
import podcastImg from "../img/icon-podcast.svg"
import NewTabLink from "./NewTabLink"

const Wrapper = styled.div`
    padding: 60px 44px;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 30px 50px;
        }
    }
`

const ContactsWrapper = styled.div`
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            flex-wrap: wrap;
            justify-content: space-between;
            padding-left: 46px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
        }
    }
`

const FlexChild = styled.div`
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            flex: 0 1 250px;
            margin-bottom: 21px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            margin-left: auto;
            margin-right: auto;
        }
    }
`

const StyledHeader = styled(FlexChild)`
    font-size: 24px;
    font-weight: 700;
    
    @media screen and (min-width: ${WIDTHS.DESKTOP}px) {
        & {
            max-width: 145px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            left: -42px;
            position: relative;
        }
    }
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 21px;
            left: 0;
            padding-bottom: 20px;
        }
    }
`

const Contact = styled(FlexChild)`
    margin-right: 20px;
    
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            margin-right: auto;
        }
    }
`

const ContactHeaderWrapper = styled.div`
    position: relative;
`

const ContactIcon = styled.img`
    position: absolute;
    left: -37px;
    top: -5px;
`

const ContactHeader = styled.div`
    font-size: 24px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 21px;  
        }
    }
`

const ContactContent = styled.div`
    padding-top: 20px;        
`

const SocialLink = styled(NewTabLink)`
    margin-right: 10px;
`

const PhoneLink = styled.a`
    text-decoration: none;
    font-size: 24px;
    font-weight: 700;
    color: black;
`

const Contacts = (): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    const Header = () => (
        <StyledHeader>
            Мы всегда на связи
        </StyledHeader>
    )

    const SocialContact = () => (
        <Contact>
            <ContactHeaderWrapper>
                <ContactIcon src={globeImg} alt="Глобус" />
                <ContactHeader>
                    Мы в социальных сетях
                </ContactHeader>
            </ContactHeaderWrapper>
            <ContactContent>
                <SocialLink href="https://vk.com/absolutins/">
                    <img src={vkImg} alt="Vkontakte" />
                </SocialLink>
                <SocialLink href="https://www.youtube.com/channel/UCevAUaB1fJB_74w-bO2TsDg/">
                    <img src={ytImg} alt="Youtube" />
                </SocialLink>
                <SocialLink href="https://t.me/absolutins">
                    <img src={tgImg} alt="Telegram" />
                </SocialLink>
                <SocialLink href="https://dzen.ru/absolutins">
                    <img src={dzenImg} alt="Dzen" />
                </SocialLink>
                <SocialLink href="https://music.yandex.ru/album/22240025">
                    <img src={podcastImg} alt="Podcast" />
                </SocialLink>
            </ContactContent>
        </Contact>
    )

    const RussiaContact = () => (
        <Contact>
            <ContactHeaderWrapper>
                <ContactIcon src={phoneImg} alt="Телефон" />
                <ContactHeader>
                    Бесплатно по России
                </ContactHeader>
            </ContactHeaderWrapper>
            <ContactContent>
                <PhoneLink href="tel:88002001838">
                    8 800 200 18 38
                </PhoneLink>
            </ContactContent>
        </Contact>
    )

    const MoscowContact = () => (
        <Contact>
            <ContactHeaderWrapper>
                <ContactIcon src={phoneImg} alt="Телефон" />
                <ContactHeader>
                    По Москве
                </ContactHeader>
            </ContactHeaderWrapper>
            <ContactContent>
                <PhoneLink href="tel:+74950257777">
                    +7 495 025 77 77
                </PhoneLink>
            </ContactContent>
        </Contact>
    )

    return (
        <Wrapper>
            { width <= WIDTHS.DESKTOP && width > WIDTHS.IPRO &&
                <Header />
            }
            <ContactsWrapper>
                { (width > WIDTHS.DESKTOP || width <= WIDTHS.IPRO) &&
                    <Header />
                }
                <>
                    { width > WIDTHS.IPRO && (
                        <>
                            <SocialContact />
                            <RussiaContact />
                            <MoscowContact />
                        </>
                    )}
                    { width <= WIDTHS.IPRO && width > WIDTHS.MOBILE && (
                        <>
                            <MoscowContact />
                            <SocialContact />
                            <RussiaContact />
                        </>
                    )}
                    { width <= WIDTHS.MOBILE && (
                        <>
                            <MoscowContact />
                            <RussiaContact />
                            <SocialContact />
                        </>
                    )}
                </>
            </ContactsWrapper>
        </Wrapper>
    )
}

export default Contacts
