import * as React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
    position: relative;
    padding-left: 55px;
    max-width: 580px;
    font-size: 18px;
`

const AdvantageImg = styled.img`
    position: absolute;
    left: 0;
    top: -12px;
`

type Props = {src: string, alt: string, text: JSX.Element}
const Advantage = (props: Props): JSX.Element => (
    <Wrapper>
        <AdvantageImg src={props.src} alt={props.alt} />
        <div>
            {props.text}
        </div>
    </Wrapper>
)

export default Advantage
