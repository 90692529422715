import * as React from "react"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"

const Wrapper = styled.div`
    font-size: 16px;
    color: #828282;
    padding: 104px 190px;
    border-top: 1px solid #E0E1E2;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 104px 46px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 37px 15px;
            font-size: 11px;
        }
    }
`
const StyledA = styled.a`
    color: #000;
`

const Footer = (): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <Wrapper>
            АО «Райффайзенбанк».{ width < WIDTHS.IPAD && <br /> } Информация действительна на 01.09.2020<br />
            Услуги страхования предоставляются<span> </span>{ width < WIDTHS.IPAD && <br /> }
            <StyledA href="https://www.absolutins.ru/" target="_blank">ООО «Абсолют Страхование»</StyledA>.<br />
            { width < WIDTHS.IPAD && <br /> }
            <StyledA href="https://www.absolutins.ru/upload/docs/persdata_bez.pdf" target="_blank">
                Политика ООО «Абсолют Страхование» в отношении обработки персональных данных.
            </StyledA>
        </Wrapper>
    )
}

export default Footer
