import * as React from "react"
import styled from "styled-components"
import { Transition } from "react-transition-group"

type WrapperType = { $status: string }
const Wrapper = styled.div<WrapperType>`
    position: fixed;
    width: 100%;
    height: 100%;
    background: #ffffffe0;
    top: 0;
    z-index: 9999999999;
    left: 0;
    
    opacity: ${(props) => (props.$status === "entered" ? "1" : "0")};
    transition: opacity 0.5s;
`

const Content = styled.div`
    left: 50vw;
    position: absolute;
    top: 50vh;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%);
    background: white;
`

const CloseButton = styled.div`
    position: absolute;
    top: 0;
    right: 21px;
    font-size: 50px;
    transform: rotate(45deg);
    cursor: pointer;
`

type Props = { open: boolean, children: JSX.Element, setOpen: (isOpen: boolean) => void }
const Popup = (props: Props): JSX.Element => (
    <Transition in={props.open} timeout={500} mountOnEnter unmountOnExit>
        {(status) => (
            <Wrapper $status={status}>
                <Content>
                    {props.children}
                    <CloseButton onClick={() => props.setOpen(false)}>+</CloseButton>
                </Content>
            </Wrapper>
        )}
    </Transition>
)

export default Popup
