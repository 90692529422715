import * as React from "react"
import styled from "styled-components"
import { Header } from "./ProtectionCarousel"
import FAQElement, { Link } from "./FAQElement"
import { WIDTHS } from "../globalStyles"

const InnerWrapper = styled.div`
    padding: 0 119px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 0 48px;
        }
    }
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {            
            padding: 0 22px;
        }
    }
`

const QuestionsHeader = styled(Header)`
    padding-left: 14px;
`

const QuestionsWrapper = styled.div`
    padding-top: 50px;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding-top: 40px;
        }
    }
`

const FAQ = (): JSX.Element => (
    <div>
        <InnerWrapper>
            <QuestionsHeader>
                Вопрос — ответ
            </QuestionsHeader>
            <QuestionsWrapper>
                {
                    [
                        {
                            q: "Что мне делать, если мой счет заблокировали?",
                            a: (
                                <div>
                                    1. Обратиться по номеру телефона – 8 800 333 09 01<br />
                                    2. Юрист в устной или письменной форме даст правовое заключение по ситуации.<br />
                                    3. Выяснить в банке основания «блокировки» счета.<br />
                                    4. Получить документ, который является основанием «блокировки» счета.<br />
                                    5. В случае несогласия с вынесенным решением
                                    (постановлением, судебным актом), необходимо обратиться с соответствующей жалобой
                                    в порядке подчиненности в вышестоящий орган
                                    (в зависимости от основания «блокировки») в установленные законом сроки или в суд
                                    с соответствующим заявлением, которое будет подготовлено с помощью наших юристов.
                                </div>
                            ),
                        },
                        {
                            q: "Как будет рассчитываться страховая выплата при простое?",
                            a: (
                                <div>
                                    За каждый день прекращения/приостановления деятельности, размер выплаты
                                    рассчитывается из текущих расходов по осуществлению деятельности и суммы прибыли
                                    Страхователя от этой деятельности за 180 календарных дней, предшествующих
                                    наступлению страхового случая. Выплата производится за каждый день остановки
                                    деятельности клиента, начиная с первого дня остановки.
                                </div>
                            ),
                        },
                        {
                            q: "Как и как быстро я могу получить авансовую выплату при страховом случае?",
                            a: (
                                <div>
                                    Для получения авансовой выплаты вам необходимо обратиться по телефону
                                    +7 (495) 025-77-77  и, следуя инструкциям, заполнить заявление и прислать нам
                                    имеющиеся у вас документы на адрес&nbsp;
                                    <Link href="mailto:uuusio@absolutins.ru">uuusio@absolutins.ru</Link>.
                                    <br />Мы выплатим вам аванс в течение 1го рабочего дня.
                                </div>
                            ),
                        },
                        {
                            q: "Какие еще сервисы входят в программу?",
                            a: (
                                <div>
                                    Письменная консультация по вопросам ИП, юридических лиц, Правовое разъяснение
                                    документа, Инструкция по составлению документа, Содействие в переговорах, Звонок
                                    юриста от Вашего имени, Обзор изменений законодательства по выбранной Вами тематике
                                </div>
                            ),
                        },
                        {
                            q: "У меня еще остались вопросы, на которые здесь нет ответа, что делать?",
                            a: (
                                <div>
                                    Любые интересующие вас вопросы, а так же необходимые документы или копии полиса
                                    страхования, вы можете получить, обратившись к нам по email:&nbsp;
                                    <Link href="mailto:support@absolutins.ru">support@absolutins.ru</Link>
                                </div>
                            ),
                        },
                    ].map((el, i) => (
                        <FAQElement q={el.q} a={el.a} key={i} />
                    ))
                }
            </QuestionsWrapper>
        </InnerWrapper>
    </div>
)

export default FAQ
