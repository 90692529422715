import * as React from "react"
import styled from "styled-components"
import { MouseEventHandler } from "react"
import { Transition } from "react-transition-group"
import { Loader } from "./SuggestInput"

type WrapperProps = { $width: string }
const Wrapper = styled.div<WrapperProps>`
    position: relative;  
    max-width: ${(props) => (props.$width ? props.$width : "229px")};
    width: ${(props) => (props.$width ? props.$width : "229px")};
`

const LoaderWrapper = styled.div`
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    right: unset;
    pointer-events: none
`

type StyledButtonProps = { $active: boolean }
const StyledButton = styled.div<StyledButtonProps>`
    font-size: 18px;
    cursor: pointer;
    background-color: ${(props) => (props.$active ? "#FEE600" : "#fee60038")};
    padding: 27px 0 6px 0;
    line-height: 0;
    border-radius: 10px;
    font-weight: 500;
    width: 100%;
    max-height: 54px;
    height: 53px;
    text-align: center;
    
    transition: background-color .5s;
`

const ButtonLoader = styled(Loader)`
    position: relative;
    right: unset;
    top: unset;
`

type Props = {
    action: MouseEventHandler,
    width?: string,
    className?: string,
    label?: string,
    useLoader?: boolean,
    showLoader?: boolean,
    active?: boolean
}
const Button = (props: Props): JSX.Element => {
    const active = props.active === undefined || props.active

    return (
        <Wrapper $width={props.width}>
            <StyledButton
                onClick={active ? props.action : null}
                className={props.className}
                $active={active}
            >
                {props.label}
            </StyledButton>
            { props.useLoader && (
                <LoaderWrapper>
                    <Transition in={props.showLoader} timeout={500} mountOnEnter unmountOnExit>
                        { (status) => <ButtonLoader $status={status} /> }
                    </Transition>
                </LoaderWrapper>
            )}
        </Wrapper>
    )
}

export default Button
