import * as React from "react"
import styled from "styled-components"
import { RefObject } from "react"
import Form from "./Form"
import { WIDTHS } from "../globalStyles"

import formLogoImg from "../img/form-logo.svg"

const OuterWrapper = styled.div`
    height: 667px;
    padding: 0 15px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 0;
        }
    }
`

const InnerWrapper = styled.div`
    background: #565F8F;
    height: 100%;
    position: relative;
`

const Circle = styled.div`
    width: 30%;
    height: 0;
    padding-bottom: 30%;
    background: #D0C8DE;
    border-radius: 100%;
    right: 10%;
    position: absolute;
    top: -20%;
    z-index: 2;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            left: -402px;
            width: 70%;
            padding-bottom: 70%;
        }
    }
`

const Triangle = styled.div`
    width: 1000px;
    height: 1000px;
    position: absolute;
    background: #759EA3;
    transform: rotate(35deg);
    right: calc(10% - 673px);
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            right: calc(10% - 800px);
        }
    }
`

const FormOuterWrapper = styled.div`
    height: 100%;
    padding: 47px 118px;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            padding: 47px 31px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            padding: 47px 16px;
        }
    }
`

const FormInnerWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    height: 100%;
    align-items: center;
`

const FormImg = styled.img`
    z-index: 2;
    position: relative;
`

const ShapesContainer = styled.div`
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: absolute;
`

const FormBlock = React.forwardRef((props, ref: RefObject<HTMLDivElement>) => {
    const [width, setWidth] = React.useState(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <OuterWrapper ref={ref}>
            <InnerWrapper>
                { width > WIDTHS.IPAD && (
                    <ShapesContainer>
                        <Circle />
                        <Triangle />
                    </ShapesContainer>
                )}
                <FormOuterWrapper>
                    <FormInnerWrapper>
                        <Form />
                        { width > WIDTHS.IPRO && (
                            <div>
                                <FormImg src={formLogoImg} alt="Документы" />
                            </div>
                        )}
                    </FormInnerWrapper>
                </FormOuterWrapper>
            </InnerWrapper>
        </OuterWrapper>
    )
})

FormBlock.displayName = "FormBlock"
export default FormBlock
