import * as React from "react"
import styled from "styled-components"
import { WIDTHS } from "../globalStyles"

export const Link = styled.a`
    color: #1990FE;
    text-decoration: none;
    white-space: nowrap;
`

type FoldingStyleProps = {$unfolded: boolean}
const Question = styled.div<FoldingStyleProps>`
    font-size: 24px;
    background-color: ${(props) => (props.$unfolded ? "#F4F5F5" : "white")};
    transition: .4s;
    position: relative;
    padding: 52px 20% 52px 52px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            padding: 52px 20% 52px 0;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 18px;
            padding: 30px 20% 30px 0;
        }
    }
`

const Plus = styled.div`
    transform: translateY(-50%);
    position: absolute;
    right: 6%;
    font-size: 40px;
    top: 50%;
`

const AnimatedPlus = styled(Plus)<FoldingStyleProps>`
    position: relative;
    transform: ${(props) => (props.$unfolded ? "rotateZ(45deg)" : "rotateZ(0)")};
    transition: transform .4s;
`

export const Answer = styled.div<FoldingStyleProps>`
    transform: ${(props) => (props.$unfolded ? "rotateX(0)" : "rotateX(90deg)")};
    transform-origin: top;
    max-height:  ${(props) => (props.$unfolded ? "1000px" : 0)};
    padding-left: 24px;
    padding-top: ${(props) => (props.$unfolded ? "52px" : 0)};
    padding-bottom: ${(props) => (props.$unfolded ? "52px" : 0)};
    font-size: 24px;
    transition: transform .4s, max-height .1s cubic-bezier(0, 1, 0, 1), padding-top .2s, padding-bottom .2s;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 18px;
        }
    }
`

const ElementWrapper = styled.div`
    border-top: 1px solid #E0E1E2;
    border-bottom: 1px solid #E0E1E2;
    border-collapse: collapse;
`

type Props = {q: string, a: JSX.Element}
const FAQElement = (props: Props): JSX.Element => {
    const [unfolded, setUnfolded] = React.useState<boolean>(false)

    return (
        <ElementWrapper>
            <Question onClick={() => setUnfolded(!unfolded)} $unfolded={unfolded}>
                <div>{props.q}</div>
                <Plus>
                    <AnimatedPlus $unfolded={unfolded}>
                        +
                    </AnimatedPlus>
                </Plus>
            </Question>
            <Answer $unfolded={unfolded}>
                {props.a}
            </Answer>
        </ElementWrapper>
    )
}

export default FAQElement
