import * as React from "react"
import styled from "styled-components"
import { MouseEventHandler } from "react"
import Button from "./Button"
import Advantage from "./Advantage"
import { WIDTHS } from "../globalStyles"
import { NoWrap } from "./BannerText"

import phoneAdvantagesImg from "../img/phone-advantages.png"
import advantage1Img from "../img/advantage-1.png"
import advantage2Img from "../img/advantage-2.png"
import advantage3Img from "../img/advantage-3.png"
import advantage4Img from "../img/advantage-4.png"

const Wrapper = styled.div`
    height: 764px;
    position: relative;
    border-top: 1px solid #DBDCDC;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            border-top: none;
            padding: 0 16px;
        }
    }
`

const ImgWrapper = styled.div`
    position: absolute;
    top: 91px;
    left: 15%;
`

const InfoWrapper = styled.div`
    left: 42%;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-evenly;
    max-width: 50%;
    
    @media screen and (max-width: ${WIDTHS.DESKTOP}px) {
        & {
            right: 3%;
            position: absolute;
            left: unset;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            max-width: 500px;
            right: unset;
            margin-left: auto;
            margin-right: auto;
            position: relative;
        }
    }
`

const Header = styled.div`
    font-size: 24px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            text-align: center;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 21px;
        }
    }
`

const AdvantagesWrapper = styled.div`
    height: 337px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            flex-grow: 0.5;
        }
    }
`
// todo: test IE
type Props = {buttonAction: MouseEventHandler}
const Advantages = (props: Props): JSX.Element => {
    const [width, setWidth] = React.useState<number>(window.innerWidth)

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setWidth(window.innerWidth)
        })
    })

    return (
        <Wrapper>
            { width > WIDTHS.IPRO && (
                <ImgWrapper>
                    <img src={phoneAdvantagesImg} alt="Телефон" />
                </ImgWrapper>
            )}
            <InfoWrapper>
                <Header>
                    Стоимость оформления — <NoWrap>9 900 руб.</NoWrap>
                </Header>
                <AdvantagesWrapper>
                    <Advantage
                        src={advantage1Img}
                        alt="Суд"
                        text={(
                            <div>
                                Поможем оперативно разблокировать счет или представим ваши интересы в суде
                            </div>
                        )}
                    />
                    <Advantage
                        src={advantage2Img}
                        alt="Вопрос"
                        text={(
                            <div>
                                Проконсультируем вас по юридическим вопросам касательно предпринимательской
                                деятельности
                            </div>
                        )}
                    />
                    <Advantage
                        src={advantage3Img}
                        alt="Аванс"
                        text={(
                            <div>
                                Сделаем аванс страховой выплаты в размере до <NoWrap>30 000 руб.</NoWrap> для
                                покрытия текущих расходов
                            </div>
                        )}
                    />
                    <Advantage
                        src={advantage4Img}
                        alt="Галочка"
                        text={(
                            <div>
                                Сами подготовим и заполним электронное заявление о страховом событии для
                                авансирования
                            </div>
                        )}
                    />
                </AdvantagesWrapper>
                <Button
                    action={props.buttonAction}
                    label="Оформить полис"
                    width={
                        width < WIDTHS.IPRO
                            ? width < WIDTHS.IPAD
                                ? "100%"
                                : "487px"
                            : null
                    }
                />
            </InfoWrapper>
        </Wrapper>
    )
}

export default Advantages
