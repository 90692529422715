import * as React from "react"

type Props = { href: string, className?: string, children: JSX.Element }
const NewTabLink = (props: Props): JSX.Element => (
    <a href={props.href} target="_blank" rel="noopener noreferrer" className={props.className}>
        {props.children}
    </a>
)

export default NewTabLink
