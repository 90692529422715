import * as React from "react"
import styled from "styled-components"
import axios from "axios"
import * as log from "loglevel"
import InputMask from "react-input-mask"
import Button from "./Button"
import { Header } from "./ProtectionCarousel"
import { WIDTHS } from "../globalStyles"
import Popup from "./Popup"

import formTickImg from "../img/form-tick.svg"
import formExclamationImg from "../img/form-exclamation.svg"
import SuggestInput from "./SuggestInput"
import Validators from "../validators"

const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: auto;
    margin-top: auto;
    height: 100%;
    z-index: 3;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            margin-left: auto;
            margin-right: auto;
        }
    }
`

const FormHeader = styled(Header)`
    color: white;
    padding-top: 0;
    margin-bottom: -20px;
    
    @media screen and (max-width: ${WIDTHS.IPRO}px) {
        & {
            text-align: center;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 30px;
        }
    }
`

const FormSubheader = styled.div`
    font-size: 24px;
    color: white;
    padding-top: 22px;
    padding-bottom: 25px;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            font-size: 21px;
        }
    }
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            text-align: center;
        }
    }
`

const InputsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 514px;
`

const InputStyle = `
    height: 53px;
    margin-bottom: 5px;
    font-size: 18px;
    padding-left: 22px;
    width: 100%;
    
    &::placeholder {
        color: black;
        top: 4px;
        position: relative;
    }
`

const StyledA = styled.a`
    color: #fff;
`

type StyledInputProps = {$validInput: boolean}
export const StyledInput = styled.input<StyledInputProps>`
    ${InputStyle};
    
    border: ${(props) => (props.$validInput ? "none" : "1px solid red")}
`

const StyledInputMask = styled(InputMask)<StyledInputProps>`
    ${InputStyle};
    
    border: ${(props) => (props.$validInput ? "none" : "1px solid red")}
`

const AdditionalInfo = styled.div`
    margin-top: 10px;
    padding-left: 20px;
    margin-bottom: 20px;
    max-width: 420px;
    position: relative;
    color: white;
    font-size: 13px;
`

const AdditionalInfoImg = styled.img`
    position: absolute;
    left: 0;
`

const PaymentWindow = styled.div`
    width: 612px;
    height: 255px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            width: 95vw;
            padding: 0 10px;
            font-size: 16px;
        }
    }
`

const PayText = styled.div`
    font-size: 24px;
`

const Buttons = styled.div`
    display: flex;
    width: 500px;
    justify-content: space-between;
    
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            flex-direction: column;
            width: 100%;
            align-items: center;
        }
    }
`

const PopupButton = styled((props) => <Button {...props} />)`
    @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            margin-bottom: 20px;
        }
    }
`

const SuccessWindow = styled.div`
    width: 612px;
    height: 255px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; 
    text-align: center;
    font-size: 24px;
    
     @media screen and (max-width: ${WIDTHS.IPAD}px) {
        & {
            width: 95vw;
            padding: 0 10px;
            font-size: 16px;
        }
    }
`

const Form = (): JSX.Element => {
    type InputNames = "company" | "phone" | "email"
    type Company = {value: Record<string, unknown>, valid: boolean}
    type Phone = {value: string, valid: boolean}
    type Email = {value: string, valid: boolean}
    type PaymentType = "CARD" | "BILL"
    type State = {
        width: number,
        inputs: {
            company: Company,
            phone: Phone,
            email: Email
        },
        paymentPopupActive: boolean,
        successPopupActive: boolean,
        submitting: boolean,
        submittingPaymentType: PaymentType | null
    }
    const [state, setState] = React.useState<State>({
        width: window.innerWidth,
        inputs: {
            company: {
                value: {},
                valid: true,
            },
            phone: {
                value: "",
                valid: true,
            },
            email: {
                value: "",
                valid: true,
            },
        },
        paymentPopupActive: false,
        successPopupActive: false,
        submitting: false,
        submittingPaymentType: null,
    })

    React.useEffect(() => {
        window.addEventListener("resize", () => {
            setState((currentState: State) => (
                {
                    ...currentState,
                    width: window.innerWidth,
                }
            ))
        })
    })

    type OnChangeTuple = [InputNames, Company["value"] | Phone["value"] | Email["value"]]
    const onChange = ([key, value]: OnChangeTuple) => {
        setState((currentState: State): State => (
            {
                ...currentState,
                inputs: {
                    ...currentState.inputs,
                    [key]: {
                        ...currentState.inputs[key],
                        value,
                    },
                },
            }
        ))
    }

    const validate = () => {
        let allValid = true
        Object.keys(state.inputs).forEach((key: InputNames) => {
            let valid = true
            switch (key) {
                case "company":
                    valid = Validators.company(state.inputs.company.value)
                    break
                case "phone":
                    valid = Validators.phone(state.inputs.phone.value)
                    break
                case "email":
                    valid = Validators.email(state.inputs.email.value)
                    break
                default:
                    break
            }
            setState((currentState) => (
                {
                    ...currentState,
                    inputs: {
                        ...currentState.inputs,
                        [key]: {
                            ...currentState.inputs[key],
                            valid,
                        },
                    },
                }
            ))
            allValid = allValid && valid
        })
        return allValid
    }

    const submit = () => {
        if (validate()) {
            setState((currentState: State): State => (
                {
                    ...currentState,
                    paymentPopupActive: true,
                }
            ))
        }
    }

    type Response = {
        success: boolean,
        url: string
    }
    const pay = (paymentType: PaymentType) => {
        if (!state.submitting) {
            setState((currentState: State): State => ({
                ...currentState,
                submitting: true,
                submittingPaymentType: paymentType,
            }))
            axios.post("/payment", {
                ...Object.keys(state.inputs).reduce((values, key: InputNames) => (
                    {
                        ...values,
                        [key]: state.inputs[key].value,
                    }
                ),
                {}),
                paymentType,
            })
                .then((res) => res.data)
                .then((data: Response) => {
                    if (data.success === true) {
                        if (paymentType === "CARD") {
                            window.location.href = data.url
                        } else {
                            setState((currentState: State): State => (
                                {
                                    ...currentState,
                                    paymentPopupActive: false,
                                    successPopupActive: true,
                                }
                            ))
                        }
                    }
                    setState((currentState: State): State => ({
                        ...currentState,
                        submitting: false,
                        submittingPaymentType: null,
                    }))
                })
                .catch((err) => {
                    log.error(err.message, JSON.stringify(err))
                    setState((currentState: State): State => ({
                        ...currentState,
                        submitting: false,
                        submittingPaymentType: null,
                    }))
                })
        }
    }

    type CompanySuggestion = {
        name: string,
        address: string,
        inn: string,
        json: Record<string, unknown>
    }

    const makeCompanySuggestionLabel = (suggestion: CompanySuggestion) =>
        `${suggestion.name}, ${suggestion.inn}, ${suggestion.address}`

    return (
        <FormWrapper>
            <FormHeader>
                Оформить полис
            </FormHeader>
            <FormSubheader>
                Сумма страховой защиты до 500 000 руб.
            </FormSubheader>
            <InputsWrapper>
                <SuggestInput
                    $validInput={state.inputs.company.valid}
                    suggestionEndpoint="/suggest/company"
                    onChange={(company) => onChange(["company", company])}
                    makeLabel={makeCompanySuggestionLabel}
                    inputPlaceholder="Название компании или ИНН"
                    name="company"
                />
                <StyledInputMask
                    $validInput={state.inputs.phone.valid}
                    mask="8 (999) 999-99-99"
                    autoComplete="off"
                    placeholder="8 (___) ____-__-__"
                    onChange={(e) => onChange(["phone", String(e.target.value)])}
                    name="phone"
                />
                <StyledInput
                    $validInput={state.inputs.email.valid}
                    type="text"
                    placeholder="ваша@почта"
                    autoComplete="off"
                    onChange={(e) => onChange(["email", String(e.target.value)])}
                    name="email"
                />
            </InputsWrapper>
            <div>
                <AdditionalInfo>
                    <AdditionalInfoImg src={formTickImg} alt="Галочка" />
                    Оплачивая страховую премию по договору, Вы соглашаетесь с правилами страхования и договором
                    офертой
                </AdditionalInfo>
                <AdditionalInfo>
                    <AdditionalInfoImg src={formTickImg} alt="Галочка" />
                    Нажимая кнопку «Оформить полис», подтверждаю свое<span> </span>
                    <StyledA href="https://www.absolutins.ru/upload/docs/persdata_sogl_landings.pdf" target="_blank">
                        Согласие
                    </StyledA>
                    <span> </span>на обработку персональных данных. С содержанием<span> </span>
                    <StyledA href="https://www.absolutins.ru/upload/docs/persdata_bez.pdf" target="_blank">
                        Политики ООО «Абсолют Страхование» в отношении обработки персональных данных
                    </StyledA>, а также положениями Федерального закона № 152-ФЗ
                    «О персональных данных» от 27 июня 2006 года ознакомлен.
                </AdditionalInfo>
                <AdditionalInfo>
                    <AdditionalInfoImg src={formExclamationImg} alt="Восклицательный знак" />
                    Внимание! На указанную электронную почту будет выслан Ваш полис! Указывайте только тот адрес,
                    которым вы пользуетесь.
                </AdditionalInfo>
            </div>
            <Button
                action={submit}
                label="Оформить полис"
                width={
                    state.width < WIDTHS.IPRO
                        ? state.width < WIDTHS.IPAD
                            ? "100%"
                            : "487px"
                        : null
                }
            />
            <Popup
                open={state.paymentPopupActive}
                setOpen={(isOpen: boolean) => setState((currentState) => (
                    {
                        ...currentState,
                        paymentPopupActive: isOpen,
                    }
                ))}
            >
                <PaymentWindow>
                    <PayText>
                        Оплатить
                    </PayText>
                    <Buttons>
                        <PopupButton
                            action={() => pay("CARD")}
                            width="240px"
                            label="Оплатить картой"
                            useLoader
                            showLoader={state.submittingPaymentType === "CARD"}
                            active={!state.submitting}
                        />
                        <PopupButton
                            action={() => pay("BILL")}
                            width="240px"
                            label="Выставить счет"
                            useLoader
                            showLoader={state.submittingPaymentType === "BILL"}
                            active={!state.submitting}
                        />
                    </Buttons>
                </PaymentWindow>
            </Popup>
            <Popup
                open={state.successPopupActive}
                setOpen={(isSuccessPopupActive: boolean) => setState((currentState) => (
                    {
                        ...currentState,
                        successPopupActive: isSuccessPopupActive,
                    }
                ))}
            >
                <SuccessWindow>
                    <div>
                        Счет и полис будут высланы на вашу почту.
                    </div>
                </SuccessWindow>
            </Popup>
        </FormWrapper>
    )
}

export default Form
